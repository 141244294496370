<script>
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";
import store from "@/state/store";

export default {
  data() {
    return {
      email: {
        val: "",
        isValid: true,
      },
      password: {
        val: "",
        isValid: true,
      },
      authError: null,
      user: null,
      showPass: false,
      formIsValid: true,
    };
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      if (this.email.val === "") {
        this.email.isValid = false;
        this.formIsValid = false;
      }
      if (this.password.val === "") {
        this.password.isValid = false;
        this.formIsValid = false;
      } else {
        this.formIsValid = true;
      }
    },
    toggleShowPassword() {
      this.showPass = !this.showPass;
    },
    async loginPatient() {
      this.validateForm();
      if (!this.formIsValid) {
        console.log("Invalid Form");
      } else {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("email", this.email.val);
        urlencoded.append("password", this.password.val);

        await axios
          .patch(
            "https://api.doctosoft.com/index.php/api/user/login",
            urlencoded
          )
          .then((response) => {
            if (response.data.id)
            {
              this.user = response.data
            }
            else {
              this.authError = response.data
        
            }
          })
          .catch((error) => {
            console.log(error);
          });
          
        if (!this.user) {
          return false;
        }
        store.commit("user/setId", this.user.id);
        store.commit("user/setName", this.user.name);
        store.commit("user/setEmail", this.user.email);
        store.commit("user/setPhone", this.user.phone);

        this.$router.push({
          path: "/",
        });
      }
    },
  },
  components: { Navbar, Footer },
  computed: {
    passType() {
      return this.showPass ? "text" : "password";
    },
  },
};
</script>

<template>
  <!-- ========================================================= -->
  <!-- <div class="navbar">
    <router-link to="/">
      <img src="/img/logo-doctosoft.491c6828.png" alt="" height="30" />
    </router-link>
    <div class="links">
      <div class="btn">
        <router-link to="/register">Signup</router-link>
      </div>
    </div>
  </div> -->
  <Navbar />

  <!-- ======================================= -->
  <div class="column">
    <div class="form-div">
      <h1>LogIn</h1>
      <b-alert v-model="authError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>
      <!-- <b-alert  variant="danger" class="mt-3" show dismissible v-if="!email.isValid">email</b-alert> -->
      <form class="needs-validation" @submit.prevent="loginPatient">
        <div class="mb-3" :class="{ invalid: !email.isValid }">
          <label for="email" class="form-label"
            >Email <span class="text-danger">*</span></label
          >
          <input
            type="email"
            class="form-control"
            v-model.trim="email.val"
            placeholder="Enter Email"
            @blur="clearValidity('email')"
          />
        </div>
        <div class="mb-3" :class="{ invalid: !password.isValid }">
          <label for="password" class="form-label"
            >Password <span class="text-danger">*</span></label
          >
          <div class="passInput">
            <input
              :type="passType"
              class="form-control"
              v-model.trim="password.val"
              placeholder="Enter Password"
              @blur="clearValidity('password')"
            />
            <b-button
              variant="link"
              class="position-absolute text-decoration-none text-muted"
              type="button"
              id="password-addon"
              @click="toggleShowPassword"
            >
              <i class="ri-eye-fill align-middle"></i>
            </b-button>
          </div>
        </div>

        <div class="mt-4">
          <b-button variant="success" class="w-100" type="submit"
            >LogIn</b-button
          >
        </div>
      </form>
    </div>
  </div>
  <Footer />
</template>

<style scoped>
.navbar {
  display: flex;
  padding: 0 1rem;
  background-color: rgb(234, 234, 255);
  align-items: center;
  height: 10vh;
  margin-bottom: 5px;
}

.links {
  padding: 10px 0px;
  display: flex;
}

.links .btn {
  border-radius: 5px;
}

.btn a {
  border: 1px solid #2aa2db;
  padding: 10px 25px;
  color: #2aa2db;
  border-radius: 10px;
}

/* ============================== */
.form-div {
  margin-left: 50%;
  transform: translateX(-50%);
  /* text-align: center; */
}

.form-div h1 {
  text-align: center;
}

.links ul {
  display: flex;
  gap: 20px;
}
.column {
  display: flex;
  flex-direction: column;
}

.form-div {
  max-width: 30rem;
  padding: 1rem;
}

.w-100 {
  height: 40px;
}
.passInput {
  position: relative;
}
.passInput button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.invalid label {
  color: red;
}
.invalid input {
  border: 1px solid red;
}
</style>
